.CircularProgressbar {

  .CircularProgressbar-trail {
    @apply stroke-progress-trail-circular;
  }

  .CircularProgressbar-path {
    @apply stroke-progress-path;
  }

  .CircularProgressbar-text {
    @apply fill-primary font-semibold;
  }
}

.swiper {

  &.hide-navigation {

    .swiper-button-prev, .swiper-button-next {
      display: none;
    }
  }
}

.react-calendar {
  .react-calendar__month-view__weekdays__weekday {
    @apply capitalize;

    abbr {
      @apply no-underline;
    }
  }

  .react-calendar__tile--active {
    @apply bg-primary;
  }

  .react-calendar__navigation button:disabled,
  .react-calendar__tile:disabled {
    background: #f8f8f8;
    color: #cdcdcd;
  }

  .react-calendar__tile.unavailable {
    color: #cdcdcd;
  }
}
