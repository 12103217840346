input::placeholder {
  @apply text-input-placeholder;
}

@layer utilities {
  @variants responsive {
    .form-field {

      label {
        @apply text-typo2 text-15p font-semibold mb-1;
      }

      input {
        @apply w-full h-11 bg-white text-black text-17p;
        @apply border border-gray rounded-10p px-4 transition-all;

        &::placeholder {
          @apply text-gray-dark;
        }
      }

      &.is-invalid {
        label {
          @apply text-danger;
        }

        input {
          @apply border-danger;
        }

        .invalid-feedback {
          @apply block text-danger;
        }
      }
    }
  }
}

.range-slider {
  width: 100%;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;

  &.gradient-range-slider {

    &::-webkit-slider-runnable-track {
      height: 4px;
      background: linear-gradient(270deg, #FF3C5F 12.03%, #FFB552 38.33%, #D7FF39 60.73%, #80FF50 74.27%, #00FF75 94.3%);
      border: none;
      border-radius: 4px;
    }
    &::-moz-range-track {
      height: 4px;
      background: linear-gradient(270deg, #FF3C5F 12.03%, #FFB552 38.33%, #D7FF39 60.73%, #80FF50 74.27%, #00FF75 94.3%);
      border: none;
      border-radius: 4px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 10px;
      height: 10px;
      background: #655E5E;
      border: none;
      border-radius: 10px;
      margin-top: -3px;
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 10px;
      height: 10px;
      background: white;
      border: none;
      border-radius: 10px;
      margin-top: -3px;
    }
  }

  &.primary-range-slider {

    &::-webkit-slider-runnable-track {
      @apply h-2 bg-white border-none rounded;
    }
    &::-moz-range-track {
      @apply h-2 bg-white border-none rounded;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      @apply w-4 h-4 bg-primary border-none rounded-xl shadow -mt-1;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      @apply w-4 h-4 bg-primary border-none rounded-xl shadow -mt-1;
    }
  }
}
