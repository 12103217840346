@font-face {
  font-family: Inter;
  src: url("../../../public/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf");
}

@layer utilities {
  @variants responsive {
    h1, .typo-h1 {
      @apply text-typo1 text-[2rem] leading-[2.875rem];
    }
    h2, .typo-h2 {
      @apply text-typo1 text-[1.5rem] leading-[2.25rem];
    }
    h3, .typo-h3 {
      @apply text-typo1 font-medium text-[1.25rem] leading-[1.5rem];
    }
    h6, .typo-h6 {
      @apply text-typo1 font-medium text-[1rem] leading-[1.375rem];
    }
    .typo-md, .typo-base {
      @apply text-[1rem] leading-[1.375rem];
    }
    p, .typo-sm {
      @apply text-[0.875rem] leading-[1.375rem];
    }
    .typo-xs {
      @apply text-[0.75rem] leading-[0.8125rem];
    }
    .typo-2xs {
      @apply text-[0.625rem] leading-normal;
    }
    .typo-button {
      @apply font-medium text-[0.875rem] leading-[1.625rem] tracking-[1px] uppercase;
    }
    .typo-button-lg {
      @apply font-semibold text-[1.0625rem] leading-[1.375rem];
    }
    .typo-helper {
      @apply text-[0.8125rem] leading-[1.125rem];
    }
    .typo-link {
      @apply text-[0.875rem] leading-[1.375rem] text-primary cursor-pointer hover:underline;
    }
  }
}

ul, ol {
  padding-inline-start: 1.25em;
}
