@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "~react-circular-progressbar/dist/styles.css";
@import '~react-calendar/dist/Calendar.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles";
